.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  color: #01A5B6;
}

.transparent-btn {
  border: none;
  background: transparent;
}

.MuiCircularProgress-colorPrimary {
  color: #01A5B6 !important;
}

/* Code for Firefox */
::-moz-selection {
  background: #01A5B6;
  color: white;
}

::selection {
  background: #01A5B6;
  color: white;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text-font {
  font-family: "Lato";
}

.text-bold {
  font-weight: bold;
}

.text-black {
  color: black;
}

.text-333 {
  color: #333333;
}

.text-666 {
  color: #666666;
}

.text-blue {
  color: #01A5B6;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.text-h1 {
  @extend .text-font;
  font-size: 2.25rem; // 36px
}

.text-h2 {
  @extend .text-font;
  font-size: 1.8rem; // 30px
}

.text-h3 {
  @extend .text-font;
  font-size: 1.6rem; // 28px
}

.text-h4 {
  @extend .text-font;
  font-size: 1.5rem; // 26px
}

.text-h5 {
  @extend .text-font;
  font-size: 1.4rem; // 24px
}

.text-h6 {
  @extend .text-font;
  font-size: 1.2rem; // 20px
}

.text-body {
  @extend .text-font;
  font-size: 1.1rem; // 18px
}

.text-caption {
  @extend .text-font;
  font-size: 1rem; // 16px
}

.text-subtitle {
  @extend .text-font;
  font-size: 0.875rem; // 14px
}

@media screen and (max-width: 491px) {
  .text-h1 {
    font-size: 1.5rem;
  }

  .text-h2 {
    font-size: 1.4rem;
  }

  .text-h3 {
    font-size: 1.2rem;
  }

  .text-h4 {
    font-size: 1.1rem;
  }

  .text-h5 {
    font-size: 1rem;
  }

  .text-h6 {
    font-size: 0.9rem;
  }

  .text-body {
    font-size: 0.9rem;
  }

  .text-caption {
    @extend .text-font;
    font-size: 0.8rem; // 16px
  }
  
  .text-subtitle {
    @extend .text-font;
    font-size: 0.6rem; // 14px
  }
}
