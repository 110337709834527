.payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 2.5rem;
    .heading {
        margin-bottom: 0.75rem;
    }
    #progressBar {
        height: 1rem;
        width: 100%;
        margin-top: 1.5rem;
        border-radius: 10px;
        background: "#1DA0BC";
    }
}
