$tryFreeBtnColor: #01a5b6;

.header-sticky {
    height: 5.5rem;
    margin-top: 1rem;
    padding-bottom: 1.19rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    z-index: 2;
    font-family: "Lato", sans-serif;
    transition: height 1s ease-in-out;
  }
  
  .header-fixed {
    @extend .header-sticky;
  
    padding-bottom: 0;
    background: #f5f5f5;
    animation: 0.4s ease-in 0s 1 slideInTopBottom;
    position: sticky;
    top: 0;
  }
  
  @keyframes slideInTopBottom {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  
  .root {
    display: flex;
    flex-direction: row;
    font-family: "Lato", sans-serif;
  }
  #menuIcon {
    display: flex;
    flex-direction: column;
    border-radius: 100px;
    background-color: #f8f8f8;
    padding: 1rem;
    margin-right: 14px;
    margin-left: 2px;
  }
  
  #closeIcon {
    display: flex;
    flex-direction: column;
    border-radius: 100px;
    background-color: #f8f8f8;
    padding: 1rem;
    margin-left: 2px;
    margin-top: 12px;
  }
  
  #menuSpan {
    width: 1.2rem;
    height: 2px;
    margin: 3px 0;
    background-color: black;
  }
  .drawerValue {
    background: white;
    height: 100%;
    z-index: 9999;
    width: 25rem;
    top: -1%;
    left: 0;
    font-family: "Lato", sans-serif;
    animation: transitionIn 0.4s;
    width: 20rem;
    position: absolute;
  
    @keyframes transitionIn {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  
  .drawerHeading {
    height: 6.6rem;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1.25rem;
  }
  .menuIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 0.625rem;
  }
  
  //this is for header icon
  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    object-fit: contain;
  }
  
  .hover {
    &:hover {
      opacity: 0.7;
    }
  }
  
  .icon {
    width: 3rem;
  }
  
  .image-fixed {
    @extend .image;
  }
  .logoStyle {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .smallImage {
    width: 1.875 rem;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .image > img {
    width: 100%;
  }
  
  #image {
    width: 100%;
  }
  
  .navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .navbarItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbarItem {
    padding: 0 1.25rem 0 1.25rem;
  }
  
  .spanNavbar {
    font-size: 18px;
    line-height: 22px;
    font-family: "Lato", sans-serif;
    color: #333333;
    white-space: nowrap;
    cursor: pointer;

    .selected {
      color: "#1DA0BC";
    }
  }
  
  .tryButton {
    padding: 0 0px 0 0px;
    white-space: nowrap;
  }
  
  a {
    text-decoration: none;
  }
  
  @media screen and (max-width: 700px) {
    .tryButton {
      padding: 0px 0px;
      white-space: nowrap;
    }
  }
  
  .brandName {
    color: #1b1b1b;
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-weight: 900;
    margin-left: 10px;
    letter-spacing: 1px;
  }
  .tagName {
    border-radius: 0px 16px;
    width: 5.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    color: #ffffff;
    height: 1.5rem;
    margin-left: 10px;
  }
  
  .displayNone {
    display: none;
  }

.headerButton {
  height: 3.125rem;
  width: 10rem;
  border-radius: 100px;
  border: 1px solid $tryFreeBtnColor;
  cursor: pointer;
  outline: none;
  background: white;

  box-sizing: border-box;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: $tryFreeBtnColor;
  font-family: "Lato", sans-serif;

  &:focus {
    outline: none;
  }

  a {
    text-decoration: none;
    color: $tryFreeBtnColor;
  }
}

.textSize {
  font-size: 18px;
  transition: 0.5s;
}

@media screen and (max-width: 491px) {
  .headerButton {
    width: 10rem;
    height: 3rem;
    font-size: 16px;
    line-height: 17px;
  }
}

.headerButtonRecc {
  @extend .headerButton;
  background-color: $tryFreeBtnColor;
  color: white;
}
