@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: transitionsIn 0.75s;
  width: 100%;
}

code {
  font-family: "Lato", sans-serif;
}

@keyframes transitionsIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  color: #01A5B6;
}