.footerBackground {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-end;
    padding-left: 18%;
    padding-right: 18%;
    padding-top: 5.539em;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    height: 385px;
    width: 100%;
  }
  
  .colFooter-2 {
    margin-bottom: 1rem;
  }
  .media-separator {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 6.25rem;
    // margin-bottom: 10rem;
    margin-bottom: 5.539em;
  }
  
  .colFooter-1 {
    padding-left: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }
  .colFooter-2,
  .colFooter-3,
  .colFooter-4 {
    @extend .colFooter-1;
  }
  
  .colFooter-2 > span:first-of-type,
  .colFooter-4 > span:first-of-type {
    font-size: 16px;
    color: #3a3a3a;
  }
  
  .colFooter-2 > span:nth-child(2),
  .colFooter-4 > span:nth-child(2) {
    font-size: 16px;
  }
  
  .colFooter-2 > span:last-of-type,
  .colFooter-4 > span:last-of-type {
    font-size: 16px;
  }
  
  #footerLogo {
    margin-top: 2rem;
  }
  .subfooter {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    height: 5.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid #ede8e8;
    margin-left: 2rem;
    margin-right: 2rem;
  
    #subfooterDetails {
      font-family: "Lato", sans-serif;
      display: block;
      font-size: 15px;
      white-space: nowrap;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .footerBackground {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }
    .col {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 7.5rem;
      margin-bottom: 2rem;
    }
  }
  
  @media screen and (max-width: 800px) {
    .footerBackground {
      padding-left: 0.5%;
      padding-right: 0.5%;
      height: 500px;
    }
    .col {
      grid-template-columns: 1fr;
      grid-template-rows: 7.5rem;
      margin-bottom: 2rem;
    }
  
    .colFooter-1,
    .colFooter-2,
    .colFooter-3,
    .colFooter-4 {
      padding-left: 0.625rem;
    }
  }
  