.Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 9999px);
    position: fixed;
    padding: 4px;
    box-sizing: border-box;
    color: #111;
}

/** Used to define the position of the ToastContainer **/

.Toastify__toast-container--top-right {
    top: 8em;
    right: 1em;
}

/** Classes for the displayed toast **/
.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    /* box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05); */
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
    // width: 300px;
}
@media screen and (max-width: 491px) {
    .Toastify__toast-container--top-right {
        top: 8em;
        right: 0.4em;
    }

    .Toastify__toast {
        max-width: 400px;
    }
}
.Toastify__toast--rtl {
    direction: rtl;
}

.Toastify__toast-body {
    margin: auto 0;
    padding: 0;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    display: none;
}

.Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
}

@keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}
@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight;
}
